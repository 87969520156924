@import '../../css/variables.scss';
.basic-input {
	width: 100%;
	margin-bottom: 16px;
	position: relative;
	$inputHeight: 40px;
	&.date-picker-input {
		input {
			line-height: $inputHeight;
		}
	}
	.label {
		color: $t2;
		font-size: 14px;
		font-weight: 600;
		margin-bottom: 8px;
		margin-left: 12px;
	}
	.input-icon-holder {
		position: absolute;
		z-index: 9;
		right: 12px;
		bottom: 0;
		width: 44px;
		height: $inputHeight;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 18px;
	}
	input,
	textarea {
		background: #fff;
		width: 100%;
		font-size: 16px;
		height: $inputHeight;
		background: #ffffff;
		box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 3px #bdc1d1;
		border-radius: 20px;
		padding: 0 24px;
		color: #1e293b;
		&:disabled {
			background: #e2e8f0;
			box-shadow: none;
			content: '';
		}
		&::placeholder {
			color: #9ca3af;
		}
		&:focus {
			box-shadow: 0 0 0 1px $indigo-500;
		}
	}
	textarea {
		min-height: 75px;
		padding: 16px;
	}
	.input {
		&::after {
			display: none !important;
		}
	}
	.read-only {
		input {
			color: $t2;
		}
	}
	.error-message {
		margin-top: 8px;
		margin-left: 24px;
		color: $red-500;
	}
	&.resizable {
		textarea {
			resize: vertical;
		}
	}
}
