@import '../../css/variables.scss';
.review-page {
	height: calc(100% - 106px);
	.page-header {
		.header-btn {
			i {
				&.f7-icons {
					margin-right: $m2;
					&.ml8 {
						margin-right: 0;
						margin-left: $m2;
					}
				}
			}
		}
	}

	.results {
		padding: $p6;
		color: $t1;
		font-size: $f4;
		height: 100%;
		padding-bottom: $p6;
		.weighted-score,
		.total-score {
			font-size: $f6;
			font-weight: 700;
			max-width: 1200px;
			margin: 0 auto;
			span {
				color: $primary;
				margin-left: $m2;
			}
		}
		.weighted-score {
			margin-bottom: $m6;
		}
		.question {
			max-width: 1200px;
			margin: 0 auto;
			margin-bottom: $m4;
			border: 1px solid $borderLight;
			padding: $p2 $p4;
			border-radius: $br2;
			.label {
				font-weight: bold;
				font-size: $f2;
				color: $coolGray-600;
			}
			.subtext {
				font-size: $f2;
				color: $f2;
				margin-left: $m4;
				margin-bottom: $m2;
			}
			.answer {
				margin-left: $m5;
				color: $f1;
				font-size: $f4;
				font-weight: bold;
				white-space: pre-wrap;
				width: 100%;
				padding-right: $p8;
			}
			.scoring-ctn {
				.score {
					font-weight: 900;
					color: $primary;
				}
				.weight {
					font-weight: 700;
					font-size: $f2;
					color: $t2;
				}
			}
		}
		.basic-input {
			max-width: 1200px;
			margin: 0 auto;
			margin-bottom: 100px;
			textarea {
				min-height: 200px;
			}
		}
	}

	.review-list {
		padding: $p6;
		max-width: 1200px;
		margin: 0 auto;
		.loading-ctn {
			padding: $p6;
		}
		.card-list {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-evenly;
			.list-card {
				padding: $p6;
				background: $coolGray-100;
				margin-right: $m6;
				margin-bottom: $m6;
				border-radius: $br2;
				box-shadow: $cardShadow;
				cursor: pointer;
				transition: 0.3s;
				width: 250px;
				height: 250px;
				.icon-ctn {
					background: $primary;
					min-width: 50px;
					min-height: 50px;
					margin-bottom: $m2;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					i {
						color: $coolGray-100;
					}
				}
				.name {
					font-weight: 700;
					font-size: $f3;
					word-wrap: break-word;
					text-align: center;
					margin-bottom: $m2;
				}
				.count {
					color: $t2;
				}
				&:hover {
					box-shadow: 0 0 0 2px $primary;
				}
			}
		}

		.search-ctn {
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: $br1;
			background: $coolGray-100;
			padding: $p1 $p2;
			margin-bottom: $m6;
			box-shadow: $cardShadow;
			i {
				font-size: $f5;
				margin-right: $m1;
			}
			.search-input {
				background: $coolGray-100;
				width: 250px;
				border: none;
			}
		}
		.empty-ctn {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			h1 {
				color: $t1;
			}
			.emptyimg {
				width: 300px;
			}
		}

		.list-item {
			background: $coolGray-100;
			padding: $p3 $p4;
			border-radius: $br1;
			box-shadow: $cardShadow;
			cursor: pointer;
			transition: 0.3s;
			margin-bottom: $m5;
			.field {
				width: 100%;
				&.assessments {
					white-space: pre;
				}
				&.accepted {
					color: $green-500;
					font-weight: 700;
				}
				&.pending {
					color: $indigo-500;
					font-weight: 700;
				}
				&.open {
					color: $indigo-500;
					font-weight: 700;
				}
				&.completed {
					color: $green-500;
					font-weight: 700;
				}
				&.reviewing {
					color: $indigo-500;
					font-weight: 700;
				}
				&.progress {
					.survey-progress {
						height: 10px;
						border-radius: $pillRadius;
					}
					.count {
						color: $t2;
						font-size: $f2;
					}
				}
			}
			.list-btn {
				min-width: 120px;
				font-size: $f2;
				box-shadow: 0 0 0 transparent;
				i {
					font-size: $f4;
				}
			}
			&:hover {
				box-shadow: 0 0 0 1px $indigo-200;
				.list-btn {
					background: $indigo-500;
					color: $white;
				}
			}
		}
	}
}
