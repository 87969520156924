@import '../../css/variables.scss';
.srvy-form-popup {
	border-radius: $br1 !important;
	color: $t1;
	background: $coolGray-50;
	.popup-header {
		display: flex;
		padding: $p4;
		i {
			font-size: $f6;
		}
		.title {
			font-size: $f6;
			font-weight: 700;
			color: $t1;
		}
	}
	.main-content {
		height: calc(100% - 145px);
		padding: 0 $p4;
		.subtitle {
			font-family: sans-serif;
			margin-bottom: $m4;
			font-size: $f2;
			color: $t2;
		}
		.form-additional-details {
			width: 100%;
			padding: $p4;
			border-radius: $br1;
			border: 1px solid $borderLight;
			.form-message {
				i {
					margin-right: $m3;
				}
				width: 100%;
				margin-bottom: $m4;
				font-size: $f2;
			}
		}
	}
	.btn-ctn {
		padding: $p4;
	}
}
