@import '../../css/variables.scss';
.group-results-page {
	background: rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(10px);
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.active-page {
		width: 80%;
		background: $coolGray-100;
		padding: $p5;
		border-radius: $br2;
		box-shadow: $cardShadow;
		.total-scores {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: 100%;
			h3 {
				width: 400px;
				text-align: left;
				color: $t2;
			}
			.score-row {
				width: 400px;
				margin-bottom: $m6;
				font-size: $f4;
				.score {
					color: $primary;
					margin-left: $m6;
				}
			}
			h1 {
				color: $primary;
			}
		}
		.page-header {
			margin-bottom: $m4;
			h1 {
				margin: 0;
			}
			.survey-progress {
				height: 10px;
				border-radius: $pillRadius;
				margin-right: $m6;
				span {
					background: linear-gradient(250deg, $indigo-600, #00e7ff);
				}
			}
		}
		.step-ctn {
			height: 60vh;
			min-height: 400px;
			margin-bottom: $m2;
			.loading-ctn {
				padding: $p6;
			}
			.active-step {
				height: 100%;
				padding-bottom: $p6;
				.start {
					text-align: center;
					height: 100%;
					h1 {
						color: $primary;
					}
				}
			}
			.results {
				height: 100%;
				.question {
					margin-bottom: $m4;
					border: 1px solid $borderLight;
					padding: $p2 $p4;
					border-radius: $br2;
					.label {
						font-weight: bold;
						font-size: $f2;
						color: $coolGray-600;
					}
					.subtext {
						font-size: $f2;
						color: $f2;
						margin-left: $m4;
						margin-bottom: $m2;
					}
					.answer {
						margin-left: $m5;
						color: $f1;
						font-size: $f4;
						font-weight: bold;
						white-space: pre-wrap;
						width: 100%;
						padding-right: $p8;
					}
				}
			}
		}
		.btn-ctn {
			width: 100%;
			.button {
				width: 100%;
				border-radius: $br2;
				margin-left: $m6;
			}
			h3 {
				min-width: 200px;
			}
		}
	}
}
