@import '../../css/variables.scss';
.survey-screen-backdrop {
	background: rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(10px);
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.active-page {
		width: 80%;
		background: $coolGray-100;
		padding: $p6;
		border-radius: $br2;
		box-shadow: $cardShadow;
		.page-header {
			.survey-progress {
				height: 10px;
				border-radius: $pillRadius;
				margin-right: $m6;
				span {
					background: linear-gradient(250deg, $indigo-600, #00e7ff);
				}
			}
		}
		.step-ctn {
			height: 60vh;
			min-height: 400px;
			margin-bottom: $m2;
			.loading-ctn {
				padding: $p6;
			}
			.active-step {
				height: 100%;
				padding-bottom: $p6;
				.start {
					text-align: center;
					height: 100%;
					h1 {
						color: $primary;
					}
				}
			}
			.question {
				padding: $p6;
				height: 80%;
				h1 {
					margin: 0;
					margin-bottom: $m6;
				}
				p {
					margin-top: -$m6;
					margin-bottom: $m6;
					color: $t2;
				}
				.multiselect-form-selector {
					ul {
						border-radius: $br2;
						box-shadow: $cardShadow;
					}
					.list {
						margin: 0;
					}
				}
				.radiogroup-form-selector {
					ul {
						border-radius: $br2;
						box-shadow: $cardShadow;
					}
					.list {
						margin: 0;
					}
				}
				.basic-input {
					input {
						height: 56px;
					}
					&.textarea-input {
						height: 100%;
						.input {
							height: 100%;
						}
						textarea {
							height: 100%;
						}
					}
				}
			}
		}
		.btn-ctn {
			transition: 0.3s;
			width: 100%;
			.button {
				width: 100%;
				border-radius: $br2;
			}
		}
	}
}
