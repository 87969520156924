@import '@/css/variables.scss';
.home-page {
	background: $coolGray-200;
	color: $t1;
	.header-bar {
		background: $coolGray-900;
		height: 50px;
		padding: 0 $p4;
		.brand-ctn {
			img {
				height: 32px;
				margin-bottom: -$m2;
			}
			.poweredby {
				font-size: 10px;
				color: $coolGray-100;
				span {
					font-size: $f1;
					font-weight: 900;
					color: $coolGray-200;
				}
			}
		}
		.header-btn {
			font-size: $f2;
			color: $coolGray-100;
			i {
				font-size: $f4;
				margin-right: $m1;
			}
		}
		.navlink {
			color: $coolGray-400;
			font-size: $f2;
			cursor: pointer;
			padding: 0 $p4;
			height: 50px;
			align-items: center;
			justify-content: center;
			display: flex;
			&:hover {
				color: $coolGray-100;
			}
			&.active {
				color: $coolGray-100;
				border-bottom: 4px solid $primary;
				height: 100%;
				display: flex;
				align-items: center;
			}
		}
	}
	.main-content {
		.page-header {
			background: $coolGray-100;
			height: 56px;
			border-bottom: 1px solid $coolGray-300;
			padding: 0 $p4;
			h1 {
				color: $coolGray-600;
				font-size: $f4;
				font-weight: 400;
			}
			.header-btn {
				font-size: $f2;
				text-transform: uppercase;
				i {
					font-size: $f4;
					margin-right: $m1;
				}

				&.m-l-24 {
					margin-left: $m6;
				}
			}
		}
		.profile-content {
			padding: $p6;
		}
		.invite-list {
			.spacer {
				min-width: 120px;
			}
		}
		.survey-list,
		.invite-list {
			padding: $p6;
			max-width: 1200px;
			margin: 0 auto;
			.loading-ctn {
				padding: $p6;
			}
			.list-header {
				padding: $p1 $p4;
				margin-bottom: $m1;
				.field {
					width: 100%;
					font-weight: 700;
				}
				.btn-spacer {
					min-width: 120px;
				}
			}
			.search-ctn {
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: $br1;
				background: $coolGray-100;
				padding: $p1 $p2;
				margin-bottom: $m3;
				box-shadow: $cardShadow;
				i {
					font-size: $f5;
					margin-right: $m1;
				}
				.search-input {
					background: $coolGray-100;
					width: 250px;
					border: none;
				}
			}

			.list-item {
				background: $coolGray-100;
				padding: $p3 $p4;
				border-radius: $br1;
				box-shadow: $cardShadow;
				cursor: pointer;
				transition: 0.3s;
				margin-bottom: $m5;
				.field {
					width: 100%;
					&.assessments {
						white-space: pre;
					}
					&.accepted {
						color: $green-500;
						font-weight: 700;
					}
					&.pending {
						color: $indigo-500;
						font-weight: 700;
					}
					&.open {
						color: $indigo-500;
						font-weight: 700;
					}
					&.completed {
						color: $green-500;
						font-weight: 700;
					}
					&.reviewing {
						color: $indigo-500;
						font-weight: 700;
					}
					&.progress {
						.survey-progress {
							height: 10px;
							border-radius: $pillRadius;
						}
						.count {
							color: $t2;
							font-size: $f2;
						}
					}
				}
				.list-btn {
					min-width: 120px;
					font-size: $f2;
					box-shadow: 0 0 0 transparent;
					i {
						font-size: $f4;
					}
				}
				&:hover {
					box-shadow: 0 0 0 1px $indigo-200;
					.list-btn {
						background: $indigo-500;
						color: $white;
					}
				}
			}
			.empty-ctn {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				h1 {
					color: $t1;
				}
				.emptyimg {
					width: 300px;
				}
			}
		}
	}
}
