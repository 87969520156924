@import '@/css/variables.scss';
.landing-page {
	width: 100%;
	height: 100%;
	color: $t1;
	.page-content {
		display: flex;
		background: $coolGray-200;
	}
	.left {
		height: 100vh;
		background: $coolGray-800;
		.title-ctn {
			padding: $p4;
			img {
				width: 80px;
				margin-right: $m6;
			}
			.title {
				font-size: $f8;
				color: $coolGray-100;
				font-weight: 900;
				text-transform: uppercase;
			}
			.subtitle {
				font-size: $f2;
				color: $coolGray-100;
				text-transform: capitalize;
				font-weight: 900;
			}
		}
		.space {
			width: 100%;
			max-height: 600px;
			max-width: 600px;
		}
	}
	.right {
		height: 100%;
		padding-top: 200px;
		.form-ctn {
			h1 {
				margin-bottom: $m6;
			}
			h2 {
				margin: 0;
				margin-bottom: $m4;
			}
			.button {
				min-width: 350px;
				margin-bottom: $m8;
			}
			.invite-ctn {
				width: 350px;
				h1 {
					margin-bottom: $m2;
					margin-top: 0;
					font-size: $f6;
				}
				.logo-ctn {
					margin-bottom: $m6;
					img {
						width: 300px;
					}
				}
				.survey {
					color: $t2;
					font-size: $f3;
					margin-bottom: $m2;
				}
			}
			.pwtext {
				width: 350px;
				margin-bottom: $m4;
				color: $t2;
			}
		}
		.input-list {
			width: 350px;
			margin-bottom: $m2;
		}
	}
}
