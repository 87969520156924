@import '../../css/variables.scss';

.dropdown-component {
	width: 100%;
	.label {
		color: $t2;
		margin-bottom: 4px;
		margin-left: 4px;
		font-weight: bold;
		font-size: 16px;
	}
	.selected-value {
		border-radius: $br1;
		width: 100%;
		padding: 8px 16px 8px 16px;
		font-size: 18px;
		text-align: left;
		margin-bottom: 16px;
		background: $bg;
		font-weight: bold;
		color: $t2;
		.ellipse {
			text-transform: capitalize;
		}
		.capitalize {
			text-transform: capitalize !important;
		}
		i {
			color: $primary;
		}
		h1 {
			color: $t1;
		}
		.fa-chevron-down {
			font-size: 22px;
		}
		.fa-user,
		.fa-clipboard-user,
		.fa-map-marked-alt,
		.fa-question,
		.fa-sparkles,
		.fa-user-md {
			font-size: 26px;
			margin-right: 16px;
		}
	}
}
