@import '../../css/variables.scss';
.srvy-form-builder {
	.srvy-dropdown {
		margin-bottom: $m6;
		height: 75px;
		.label {
			color: $t2;
			margin-bottom: 4px;
			margin-left: 4px;
			font-weight: 600;
			font-size: $f2;
			font-family: Roboto, sans-serif !important;
		}
		.selected-value {
			border-radius: $br1;
			margin-bottom: 0;
			i {
				margin-right: $m4;
				font-size: $f4;
			}
			.fa-chevron-down {
				margin: 0;
			}
			div {
				color: $t1 !important;
				font-size: $f3;
				font-weight: 600;
				font-family: Roboto, sans-serif !important;
			}
		}
	}
	.basic-input {
		.label {
			font-weight: 600;
			font-size: $f2;
			font-family: Roboto, sans-serif !important;
		}
		margin-bottom: $m6;
		.input-error-message {
			margin-top: $m1;
			margin-left: $m4;
		}
		.input-with-infom,
		.input-with-error-message {
			padding-bottom: 0 !important;
			margin-bottom: $m7 !important;
		}
	}
	.form-row {
		.basic-input {
			&:first-child {
				margin-right: $m4;
			}
		}
		.express-dropdown {
			&:first-child {
				margin-right: $m4;
			}
		}
	}
	.srvy-form-toggle {
		margin-bottom: $m6;
		padding: $p3;
		border: 1px solid $borderLight;
		border-radius: $br1;
		.label {
			color: $t2;
			font-weight: 600;
			font-size: $f2;
			flex-grow: 1;
			font-family: Roboto, sans-serif !important;
		}
	}
	.multiselect-form-selector {
		margin-bottom: $m6;
		.label {
			color: $t2;
			margin-bottom: 4px;
			margin-left: 4px;
			font-weight: 600;
			font-size: $f2;
			font-family: Roboto, sans-serif !important;
		}
		ul {
			border-radius: $br2;
			box-shadow: $cardShadow;
		}
		.list {
			margin: 0;
		}
	}
}

.formbuilder-dropdown-internalGroupId,
.formbuilder-dropdown-internalPlanId,
.formbuilder-dropdown-state,
.formbuilder-dropdown-memberDataType {
	min-width: 400px;
	.popover-inner {
		min-width: 400px;
	}
	.list {
		max-height: 400px;
	}
}
