@import './layout.scss';
@import './variables.scss';
@import './icons.scss';

/* Custom color theme properties */
:root {
	--f7-theme-color: #6875f5;
	--f7-theme-color-rgb: 104, 117, 245;
	--f7-theme-color-shade: #4252f2;
	--f7-theme-color-tint: #8e98f8;
}

body {
	font-family: 'Work Sans', sans-serif !important;
}

div {
	box-sizing: border-box;
}

.hidden {
	visibility: hidden;
}

.ellipse {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.y-scroll {
	overflow-y: scroll;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch !important;
}

.x-scroll {
	overflow-x: scroll;
	overflow-y: hidden;
	-webkit-overflow-scrolling: touch !important;
}

.button {
	border-radius: $br1;
	text-transform: capitalize;
	font-size: $f4;
	&.button-fill {
		box-shadow: 0 0 6px $indigo-400;
		&:hover {
			box-shadow: 0 5px 15px $indigo-400;
		}
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	background-color: $red-500 !important;
	background: $red-500 !important;
}

.input-list {
	margin: 0;
	ul {
		background: transparent;
		li {
			.item-input {
				padding: 0;
				.item-inner {
					padding: $p3 0;
				}
				.item-label {
					font-weight: 600;
					margin-bottom: $m1;
					margin-left: $m2;
					font-size: $f2;
					color: $t2;
				}
				.item-input-wrap {
					background: $coolGray-100;
					border-radius: $br1;
					box-shadow: 0 0 0 1px $coolGray-300;
					input {
						padding-left: 8px;
					}
					input:-webkit-autofill,
					input:-webkit-autofill:hover,
					input:-webkit-autofill:focus,
					input:-webkit-autofill:active {
						border-radius: $br1;
						-webkit-box-shadow: 0 0 0 30px $coolGray-100 inset !important;
						box-shadow: 0 0 0 30px $coolGray-100 inset !important;
					}

					.item-input-error-message {
						padding-left: 8px;
						color: $red-600;
					}
					&::after {
						height: 0 !important;
					}
				}
				&.item-input-focused {
					.item-input-wrap {
						box-shadow: 0 0 0 2px $indigo-400;
					}
				}
			}
		}
	}
}

.reauth-ctn {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.3);
	z-index: 999999;
	display: flex;
	justify-content: center;
	align-items: center;
	.reauth {
		color: $coolGray-800;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		border-radius: $br2;
		background: rgba(255, 255, 255, 0.74);
		backdrop-filter: blur(6px);
		padding: $p8;
		box-shadow: 4px 4px 10px rgba(59, 130, 246, 0.15);
		i {
			color: $indigo-600;
			font-size: $f8;
		}
	}
}

.round-btn {
	border-radius: $br2;
	min-width: 200px;
	box-shadow: 0px 0px 20px rgba(59, 130, 246, 0.5);

	i {
		margin-left: $m1;
	}
	&.button-small {
		border-radius: $br1;
		font-size: $f2;
		font-weight: 600;
	}
}

.list-btn {
	border-radius: $br1;
	height: 32px;
	border: none;
	box-shadow: $smallBtnShadow;

	i {
		margin-right: $m1;
	}
}

::-webkit-scrollbar-track {
	border-radius: 6px;
	background-color: $coolGray-100;
}

::-webkit-scrollbar {
	width: 4px;
	height: 0px;
	background-color: $bg;
}

::-webkit-scrollbar-thumb {
	border-radius: 6px;
	background-color: $indigo-600;
}
