@import '../../css/variables.scss';
.assessments-page {
	height: calc(100% - 106px);
	.page-header {
		.header-btn {
			i {
				&.f7-icons {
					margin-right: $m2;
					&.ml8 {
						margin-right: 0;
						margin-left: $m2;
					}
				}
			}
		}
	}

	.assessment-scoring {
		.scoring-ctn {
			max-width: 900px;
			margin: 0 auto;
			h2 {
				span {
					color: $primary;
				}
				div {
					text-align: left;
					&.red {
						color: $red-500;
					}
				}
				text-align: right;
			}
			.question-item {
				width: 100%;
				margin-bottom: $m6;
				.q-a-p {
					color: $t1;
					font-size: $f3;
					.question {
						span {
							font-weight: bold;
							margin-right: $m2;
						}
						width: 100%;
						word-wrap: break-word;
						max-width: 800px;
					}
					.points {
						text-align: right;
						min-width: 100px;
						font-size: $f4;
						span {
							font-weight: bold;
							color: $indigo-500;
						}
					}
				}
			}
		}
	}

	.assessment-scoring,
	.assessment-builder {
		padding: $p6;
		height: 100%;
		.clone-banner {
			width: 100%;
			margin-bottom: $m8;
			padding: $p3;
			background-color: $red-100;
			border-radius: $br2;
			border: 2px solid $red-600;
			color: $red-600;
			h3 {
				margin: 0;
			}

			.clone-btn {
				margin-top: $m4;
				width: 100px;
			}
		}
		.builder-header {
			max-width: 1200px;
			margin: 0 auto;
			margin-bottom: $m8;
			.assessment-name-input {
				width: 300px;
				min-width: 300px;
				margin-right: $m6;
			}
			.explainer {
				max-width: 600px;
			}
		}
		.question-container {
			max-width: 900px;
			margin: 0 auto;
			.question-card {
				background: $coolGray-100;
				padding: $p4;
				border-radius: $br2;
				box-shadow: $cardShadow;
				margin-bottom: $m8;
				.dropdown-component {
					.selected-value {
						margin-bottom: 0;
					}
					.subtext {
						color: $t2;
						font-size: $f1;
						text-align: left;
						margin-left: $m4;
					}
				}
				.question-type-dd {
					width: 250px;
					margin-left: $m4;
				}
				.divider {
					height: 1px;
					background: $coolGray-300;
					margin-bottom: $m4;
				}
				.points {
					margin-right: $m8;
					.label {
						font-size: $f3;
						color: $t2;
						font-weight: 600;
					}
				}
				.options {
					.option-label {
						margin-left: $m1;
						color: $t2;
						font-size: $f3;
						font-weight: 700;
						span {
							font-size: $f2;
						}
					}
					.option {
						margin-bottom: $m4;
						i {
							color: $t1;
							margin-left: $m4;
							cursor: pointer;
							&:hover {
								color: $primary;
							}
						}
						.option-input {
							padding: $p2 $p3;
							border-radius: $br1;
							border: 1px solid $borderLight;
							margin-right: $m8;
						}
						.points {
							margin-right: 0px;
							.label {
								font-size: $f3;
								color: $t2;
								font-weight: 600;
								margin-right: $m1;
							}
						}
					}
				}
			}
		}
		.add-question-actions {
			padding: $p6;
			.add-question-btn {
				max-width: 300px;
				width: 300px;
				color: $coolGray-500;
				border-radius: $br2;
				border-color: $coolGray-500;
				text-transform: uppercase;
				&:hover {
					border-color: $primary;
					color: $primary;
				}
			}
		}
	}
	.assessment-list {
		padding: $p6;
		max-width: 1200px;
		margin: 0 auto;
		.loading-ctn {
			padding: $p6;
		}
		.card-list {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-evenly;
			.list-card {
				padding: $p6;
				background: $coolGray-100;
				margin-right: $m6;
				margin-bottom: $m6;
				border-radius: $br2;
				box-shadow: $cardShadow;
				cursor: pointer;
				transition: 0.3s;
				width: 250px;
				height: 250px;
				.icon-ctn {
					background: $primary;
					min-width: 50px;
					min-height: 50px;
					margin-bottom: $m2;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					i {
						color: $coolGray-100;
					}
				}
				.name {
					font-weight: 700;
					font-size: $f3;
					word-wrap: break-word;
					text-align: center;
					margin-bottom: $m2;
				}
				.count {
					color: $t2;
				}
				&:hover {
					box-shadow: 0 0 0 2px $primary;
				}
			}
		}

		.search-ctn {
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: $br1;
			background: $coolGray-100;
			padding: $p1 $p2;
			margin-bottom: $m6;
			box-shadow: $cardShadow;
			i {
				font-size: $f5;
				margin-right: $m1;
			}
			.search-input {
				background: $coolGray-100;
				width: 250px;
				border: none;
			}
		}
		.empty-ctn {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			h1 {
				color: $t1;
			}
			.emptyimg {
				width: 300px;
			}
		}

		.list-item {
			background: $coolGray-100;
			padding: $p3 $p4;
			border-radius: $br1;
			box-shadow: $cardShadow;
			cursor: pointer;
			transition: 0.3s;
			margin-bottom: $m5;
			.field {
				width: 100%;
				&.assessments {
					white-space: pre;
				}
				&.accepted {
					color: $green-500;
					font-weight: 700;
				}
				&.pending {
					color: $indigo-500;
					font-weight: 700;
				}
				&.open {
					color: $indigo-500;
					font-weight: 700;
				}
				&.completed {
					color: $green-500;
					font-weight: 700;
				}
				&.reviewing {
					color: $indigo-500;
					font-weight: 700;
				}
				&.progress {
					.survey-progress {
						height: 10px;
						border-radius: $pillRadius;
					}
					.count {
						color: $t2;
						font-size: $f2;
					}
				}
			}
			.list-btn {
				min-width: 120px;
				font-size: $f2;
				box-shadow: 0 0 0 transparent;
				i {
					font-size: $f4;
				}
			}
			&:hover {
				box-shadow: 0 0 0 1px $indigo-200;
				.list-btn {
					background: $indigo-500;
					color: $white;
				}
			}
		}
	}
}
